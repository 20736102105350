<template>
  <section id="about" class="overflow-hidden py-20 sm:py-32 lg:pb-32 xl:pb-36">
    <div class="container">
      <div class="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
        <div class="relative z-10 mx-auto max-w-2xl lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6">
          <h1 class="text-4xl font-medium tracking-tight text-gray-900">
            We deliver blockchain Events as webhooks.
          </h1>
          <p class="mt-6 text-lg text-gray-600">
            23gate is an <b>open-source</b> server-side software that watches for Solidity and EVM events of your choice and deliver webhooks to your app.
            It takes care of managing confirmations, forks and recovery. You receive a reliable, clean webhook once
            the event has definitely happened.
          </p>
          <div class="mt-8 flex flex-wrap gap-x-6 gap-y-4">
            <a href="https://github.com/23gate/23gate/" class="btn-primary">Start getting webhooks</a>
          </div>
        </div>

        <hero-image class="mt-10 sm:mt-20 lg:col-span-5 lg:row-span-2 lg:mt-0 xl:col-span-6">
          <illustration
            v-motion
            :initial="{ opacity: 0, scale: 0.9, y: 100 }"
            :enter="{ opacity: 1, scale: 1, y: 0 }"
            :delay="300"
          />
        </hero-image>

        <div class="relative -mt-4 lg:col-span-7 lg:mt-0 xl:col-span-6">
          <p class="text-center text-sm font-semibold text-gray-900 lg:text-left">Used by:</p>
          <ul role="list" class="mx-auto mt-8 flex max-w-xl flex-wrap justify-center gap-x-10 gap-y-8 lg:mx-0 lg:justify-start">
            <li v-for="(item, key) in partners" class="flex items-center">
              <img
                :alt="item.name"
                :src="item.src"
                decoding="async"
                class="h-8 text-transparent"
                :class="item.class"
                loading="lazy"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import HeroImage from '@/components/HeroImage.vue';
import Illustration from './Illustration.vue';

const partners = [
  {
    name: 'Ariadne',
    src: '/ariadne.svg'
  }
];
</script>