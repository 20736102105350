<template>

  <transition
    enter-active-class="transition ease-in-out duration-200 transform"
    enter-from-class="-translate-y-full"
    enter-to-class="translate-y-0"
    leave-active-class="transition ease-in-out duration-200 transform"
    leave-from-class="translate-y-0"
    leave-to-class="-translate-y-full"
    appear
  >
    <the-navbar :active-link="activeLink" class="relative z-[1]" />
  </transition>

  <index-page v-model="activeLink">
    <transition
      enter-active-class="transition ease-in-out duration-200 transform"
      enter-from-class="-translate-y-full"
      enter-to-class="translate-y-0"
      leave-active-class="transition ease-in-out duration-200 transform"
      leave-from-class="translate-y-0"
      leave-to-class="-translate-y-full"
      appear
    >
      <the-footer class="grow-0 shrink-0" />
    </transition>
  </index-page>
</template>

<script setup>
import { ref } from 'vue';
import TheNavbar from '@/components/TheNavbar.vue';
import TheFooter from '@/components/TheFooter.vue';
import IndexPage from '@/IndexPage.vue';

const activeLink = ref(null);
</script>
