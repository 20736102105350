<template>
  <section id="team" class="py-20 sm:py-32 lg:pb-32 xl:pb-36">
    <div class="container">
      <div class="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
        <div class="space-y-5 sm:space-y-4">
          <h2 class="text-3xl font-bold tracking-tight sm:text-4xl">About Us.</h2>
          <p class="text-xl text-gray-500">
            23gate was created by the team behind <a href="https://ariadne.finance/">Ariadne</a>, a crosschain DeFi market place and yield farm aggregator.
            Our need to watch for EVM with utmost reliability lead us to create this software.
          </p>
        </div>
        <div class="lg:col-span-2">
          <ul role="list" class="space-y-16 sm:-mt-8 sm:space-y-0 sm:divide-y sm:divide-gray-200 lg:gap-x-8 lg:space-y-0">
            <li v-for="person in people" :key="person.name" class="sm:py-8">
              <div class="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                <div>
                  <img class="rounded-lg object-cover shadow-lg" :src="person.imageUrl" :alt="person.name" />
                </div>
                <div class="sm:col-span-2">
                  <div class="space-y-4">
                    <div class="space-y-1 text-lg font-medium leading-6">
                      <h3>{{ person.name }}</h3>
                      <p class="text-gray-400">{{ person.role }}</p>
                    </div>
                    <div class="sm:text-lg">
                      <p class="text-gray-500">{{ person.bio }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
const people = [
  {
    name: 'Eugene Shimalsky',
    role: 'CEO',
    imageUrl: '/eugene.jpg',
    bio: 'Product and business development executive. Founder of Noisy Miner software development studio, ex Product Director at Deep Dive Research/Paytomat.',
  },
  {
    name: 'Egor Egorov',
    role: 'CTO',
    imageUrl: '/egor.jpg',
    bio: 'Software developer with over 30 years of coding experience. Egor specializes in financial and high-performance trading software.',
  },
  {
    name: 'Roman Zhbadynskyi',
    role: 'Lead full stack developer',
    imageUrl: '/roman.jpg',
    bio: 'Senior developer with 15+ years of experience. Focused on usability and performance improvements.',
  },
]
</script>
