<template>
    <div id="scroll-container" ref="target" class="fixed top-0 bottom-0 pt-10 sm:pt-36 w-full h-full overflow-y-auto flex flex-col">
      <div class="grow">
        <about v-intersection-observer="[onIntersectionObserver, { root: target, threshold: 0.5 }]" />

        <features v-intersection-observer="[onIntersectionObserver, { root: target, threshold: 0.5 }]" />

        <team v-intersection-observer="[onIntersectionObserver, { root: target, threshold: 0.5 }]" />

        <faq v-intersection-observer="[onIntersectionObserver, { root: target, threshold: 0.5 }]" />
      </div>
      <slot />
    </div>
</template>

<script setup>
import { ref } from 'vue';
import About from '@/components/About.vue';
import Features from '@/components/Features.vue';
import Team from '@/components/Team.vue';
import Faq from '@/components/Faq.vue';

import { vIntersectionObserver } from '@vueuse/components';

const target = ref(null);

const emit = defineEmits(['update:modelValue']);

function onIntersectionObserver([{ isIntersecting, target }]) {
  if (isIntersecting) {
    emit('update:modelValue', target?.id || null);
  }
}
</script>
