import { ViteSSG } from 'vite-ssg/single-page';
import App from './App.vue';
import { FontAwesomeIcon } from '@/fontawesome';
import VueSmoothScroll from 'vue3-smooth-scroll';
import { MotionPlugin } from '@vueuse/motion';

import './assets/style/main.scss';

export const createApp = ViteSSG(
  App,
  ({ app, initialState }) => {
    // install plugins
    app.component('font-awesome-icon', FontAwesomeIcon);
    app.use(VueSmoothScroll, {
      duration: 500,
      container: '#scroll-container'
    });
    app.use(MotionPlugin);
  },
);
