<template>
  <section id="features" aria-label="Features for investing all your money" class="bg-gray-900 py-20 sm:py-32">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-3xl">
        <h2 class="text-3xl font-medium tracking-tight text-white">
          Developers for developers. Absolutely clear.
        </h2>
        <p class="mt-2 text-lg text-gray-400">
          We hate marketingspeak. 23gate was built by developers, for developers. We want to start delivering webhooks to your code and then get out of your way. We are here to save you time.
        </p>
      </div>
    </div>

    <div class="mt-16 md:hidden">
      <div ref="target" class="-mb-4 flex snap-x snap-mandatory -space-x-4 overflow-x-auto overscroll-x-contain scroll-smooth pb-4 [scrollbar-width:none] sm:-space-x-6 [&::-webkit-scrollbar]:hidden">

        <div
          v-for="(item, key) in features"
          :key="'mobile_'+key"
          class="w-full flex-none snap-center px-4 sm:px-6"
          :id="`slide_${key}`"
          :data-id="key"
          v-intersection-observer="[onIntersectionObserver, { root: target, threshold: 0.5 }]"
        >
          <div class="relative transform overflow-hidden rounded-2xl bg-gray-800 px-5 py-6">
            <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              <svg viewBox="0 0 558 558" width="558" height="558" fill="none" class="animate-spin-slower -z-10" aria-hidden="true">
                <defs>
                  <linearGradient id=":R2m9m:" x1="79" y1="16" x2="105" y2="237" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#13B5C8"></stop>
                    <stop offset="1" stop-color="#13B5C8" stop-opacity="0"></stop>
                  </linearGradient>
                </defs>
                <path opacity=".2" d="M1 279C1 125.465 125.465 1 279 1s278 124.465 278 278-124.465 278-278 278S1 432.535 1 279Z" stroke="#13B5C8"></path>
                <path d="M1 279C1 125.465 125.465 1 279 1" stroke="url(#:R2m9m:)" stroke-linecap="round"></path>
              </svg>
            </div>

            <div class="relative aspect-[366/729] mx-auto w-full max-w-[366px]">
              <browser>
                <img :src="item.image" class="object-cover w-full h-full" alt="">
              </browser>
            </div>

            <div class="absolute inset-x-0 bottom-0 bg-gray-800/80 p-6 backdrop-blur sm:p-10">
              <font-awesome-icon :icon="item.icon" class="h-8 w-8 text-slate-400" aria-hidden="true" />
              <h3 class="mt-6 text-sm font-semibold text-white sm:text-lg">
                {{ item.name }}
              </h3>
              <p class="mt-2 text-sm text-gray-400">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>

      </div>
      <div class="mt-6 flex justify-center gap-3">
        <button
          v-for="index in Object.keys(features)"
          :key="'btn_'+index"
          class="relative h-0.5 w-4 rounded-full"
          :class="currentFeature === index ? 'bg-slate-300' : 'bg-slate-500'"
          :aria-label="`Go to slide ${index + 1}}`"
          @click="goToSlide(index)"
        >
          <span class="absolute -inset-x-1.5 -inset-y-3"></span>
        </button>
      </div>
    </div>

    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 hidden md:mt-20 md:block">
      <TabGroup :defaultIndex="0" as="div" class="grid grid-cols-12 items-center gap-8 lg:gap-16 xl:gap-24" vertical>
        <TabList class="relative z-10 order-last col-span-6 space-y-6">
          <Tab
            v-slot="{ selected }"
            v-for="(item, key) in features"
            :key="'fs_'+key"
            as="div"
            class="relative rounded-2xl transition-colors hover:bg-gray-800/30"
          >
            <div v-if="selected" class="absolute inset-0 bg-gray-800 rounded-2xl" />
            <div class="relative z-10 p-8">
              <font-awesome-icon :icon="item.icon" class="h-8 w-8 text-slate-400" aria-hidden="true" />
              <h3 class="mt-6 text-lg font-semibold text-white">
                <button class="text-left">
                  <span class="absolute inset-0 rounded-2xl"></span>
                  {{ item.name }}
                </button>
              </h3>
              <p class="mt-2 text-sm text-gray-400">
                {{ item.description }}
              </p>
            </div>
          </Tab>
        </TabList>
        <div class="relative col-span-6">
          <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <svg viewBox="0 0 558 558" width="558" height="558" fill="none" aria-hidden="true" class="animate-spin-slower">
              <defs>
                <linearGradient id=":R3b9m:" x1="79" y1="16" x2="105" y2="237" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#13B5C8"></stop>
                  <stop offset="1" stop-color="#13B5C8" stop-opacity="0"></stop>
                </linearGradient>
              </defs>
              <path opacity=".2" d="M1 279C1 125.465 125.465 1 279 1s278 124.465 278 278-124.465 278-278 278S1 432.535 1 279Z" stroke="#13B5C8"></path>
              <path d="M1 279C1 125.465 125.465 1 279 1" stroke="url(#:R3b9m:)" stroke-linecap="round"></path>
            </svg>
          </div>
          <div class="relative aspect-[366/729] z-10 mx-auto w-full max-w-[366px]">
            <TabPanels as="div" class="relative col-span-6 h-full">
              <TabPanel
                v-for="(item, key) in features"
                :key="'fs_img_'+key"
                as="div"
                class="col-start-1 row-start-1 flex w-full h-full [mask-image:linear-gradient(to_bottom,white_60%,transparent)]"
                v-motion
                :initial="{ opacity: 0, scale: 0.9, y: 100 }"
                :enter="{ opacity: 1, scale: 1, y: 0 }"
                :leave="{ opacity: 0, scale: 0.9, y: 100 }"
                :delay="100"
              >
                <browser>
                  <img :src="item.image" class="object-cover w-full h-full" alt="">
                </browser>
              </TabPanel>
            </TabPanels>
          </div>
        </div>
      </TabGroup>
    </div>

  </section>
</template>

<script setup>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
import { ref, shallowRef } from 'vue';
import { vIntersectionObserver } from '@vueuse/components';
import Browser from '@/components/Browser.vue';

const features = [
  {
    name: "Support for different blockchains",
    description: "We monitor events on any EVM-compatible networks with an EVM-compatible RPC.",
    icon: 'fa-solid fa-pills',
    image: '/networks.png'
  },
  {
    name: "Automatically detect forks and lost transactions",
    description:
      "After observing an event we wait for confirmations on each relevant transaction before " +
      "considering the event as definitely happened and sending out a webhook (COMING SOON)",
    icon: 'fa-solid fa-face-dizzy',
    image: '/confirmations.png',
  },
  {
    name: "Extremely clear JSON payload",
    description: "Our goal is to make it easy for you to understand the event simply by looking at the JSON payload. We have it documented, too.",
    icon: 'fa-solid fa-pizza-slice',
    image: '/payload.png',
  },
];

const target = ref(null);
const currentFeature = shallowRef(0);

function onIntersectionObserver([{ isIntersecting, target }]) {
  if (isIntersecting) {
    currentFeature.value = target.dataset.id;
  }
}

function goToSlide(index) {
  document.getElementById(`slide_${index}`).scrollIntoView({
    block: 'nearest',
    inline: 'nearest',
  });
}
</script>
