<template>
  <section id="faq" aria-labelledby="faqs-title" class="border-t border-b border-gray-200 py-20 sm:py-32">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 id="faqs-title" class="text-3xl font-medium tracking-tight text-gray-900">Frequently asked questions</h2>
        <p class="mt-2 text-lg text-gray-600">
          If you have anything else you want to ask,
          <a class="link-dashed" href="mailto:me@egorfine.com?subject=23gate">get in touch with us</a>.
        </p>
      </div>
      <ul role="list" class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 sm:mt-20 lg:max-w-none lg:grid-cols-3">
        <li>
          <ul role="list" class="space-y-10">
            <li>
              <h3 class="text-lg font-semibold leading-6 text-gray-900">What is the price?</h3>
              <p class="mt-4 text-sm text-gray-700">
                <a class="link-dashed" href="https://github.com/23gate/23gate/">This is a free open source software.</a> Should you need a cloud instance managed by us — please <a  class="link-dashed" href="mailto:me@egorfine.com?subject=23gate">get in touch with us</a>.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <ul role="list" class="space-y-10">
            <li>
              <h3 class="text-lg font-semibold leading-6 text-gray-900">What networks are supported?</h3>
              <p class="mt-4 text-sm text-gray-700">
                Any EVM-compatible network with a standard EVM-compatible RPC endpoint.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <ul role="list" class="space-y-10">
            <li>
              <h3 class="text-lg font-semibold leading-6 text-gray-900">Why can't I just watch for Events in my own code?</h3>
              <p class="mt-4 text-sm text-gray-700">
                It's not that simple to keep track of all blocks and not miss any events in case your code gets disconnected or your app is
                down for a non-brief period. So you have to introduce local state and keep track of blocks. Next, you should wait for every
                transaction with relevant events to finalize and not get lost in the forks. So you have to keep track of transactions. This
                gets complicated fairly quickly. <a class="link-dashed" href="https://medium.com/ariadne-project/webhooks-for-evm-events-a-new-service-for-developers-ded0456f2c2e">See this article for a more detailed explanation</a>.
              </p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </section>
</template>
