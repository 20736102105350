import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faTwitter,
  faFacebook,
  faTelegram,
  faGithub
} from '@fortawesome/free-brands-svg-icons';

import {
  faEnvelope,
  faArrowDown,
  faLock,
  faFaceDizzy,
  faPills,
  faPizzaSlice
} from '@fortawesome/free-solid-svg-icons';

/* add icons to the library */
library.add(
  faTwitter,
  faFacebook,
  faTelegram,
  faGithub,
  faEnvelope,
  faArrowDown,
  faLock,
  faFaceDizzy,
  faPills,
  faPizzaSlice
);

export { FontAwesomeIcon };
